<div class="header">
  <div class="title cta-x1-semi-bold">
    {{ 'Project.Detail.HistoryLog.Header.Label' | translate }}
  </div>
  <div class="action">
    <div
      class="close clickable clickable__wrapper btn__icon-rectangle btn__icon-rectangle--default border-radius--4"
      (click)="onClose()"
    >
      <img src="assets/icons/close-white.svg" alt="close" />
    </div>
  </div>
</div>
<div class="body">
  <div class="key-history-container d--flex flex__direction--column gap--8">
    <ng-container *ngIf="historyLog$ | async; let historyLog">
      <ng-container *ngIf="historyLog?.length; else noHistoryDisplay">
        <div class="label-xs-medium date-modify" *ngIf="historyLog?.length">
          {{ 'Project.Detail.HistoryLog.LastModify.Label' | translate }}:
          {{ historyLog[0]?.createdAt | customFormatDate : CustomDateFormatEnum.dateMonthYear }}
        </div>
        <app-key-history-item *ngFor="let item of historyLog" [historyLog]="item"></app-key-history-item>
      </ng-container>
      <ng-template #noHistoryDisplay>
        <span class="label-maj-xs--medium--red-100">
          {{ 'Project.Detail.HistoryLog.NoHistory.Label' | translate | uppercase }}
        </span>
      </ng-template>
    </ng-container>

    <ng-container *ngIf="!editedItemId || !draftId">
      <span class="label-maj-xs--medium--red-100">
        {{ 'Project.Detail.HistoryLog.NoHistory.Label' | translate | uppercase }}
      </span>
    </ng-container>
  </div>
</div>
