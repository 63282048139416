import { FormControl, ReactiveFormsModule } from '@angular/forms';
import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef, EventEmitter,
  Input,
  NgModule,
  Output,
  ViewChild,
} from '@angular/core';
import { CdkTextareaAutosize, TextFieldModule } from '@angular/cdk/text-field';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';

@Component({
  selector: 'app-ui-textarea',
  templateUrl: './textarea.component.html',
  styleUrls: ['./textarea.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TextareaComponent implements AfterViewInit {
  public textAreaHeight = '60px';

  @Input() textAreaId: string;
  @Input() inputMaxLength = 2000;
  @Input() control: FormControl;
  @Output() blurControl = new EventEmitter<string>();

  @ViewChild('autosize') autosize: CdkTextareaAutosize;
  @ViewChild('textareaField') textareaField: ElementRef;

  constructor(private _changeDetectorRef: ChangeDetectorRef) {}

  ngAfterViewInit(): void {
    this.autosize?.resizeToFitContent(true);
    this.calculateTextAreaHeight();
  }

  public calculateTextAreaHeight() {
    this.textAreaHeight =
      ((this.autosize as unknown as any)?._elementRef?.nativeElement
        ?.clientHeight || 60) +
      16 +
      'px';
    this._changeDetectorRef.detectChanges();
  }
}

@NgModule({
  declarations: [TextareaComponent],
  exports: [TextareaComponent],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    TranslateModule,
    MatFormFieldModule,
    TextFieldModule,
    MatInputModule,
  ],
})
export class TextareaModule {}
