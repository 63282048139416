<div class="header">
  <div class="title cta-x1-semi-bold">
    {{ 'Project.Detail.DraftHistoryLog.Header.Label' | translate }}
  </div>
  <div class="action">
    <div class="close clickable btn__icon-rectangle btn__icon-rectangle--default border-radius--4" (click)="onClose()">
      <img src="assets/icons/close-white.svg" alt="close" />
    </div>
  </div>
</div>
<div class="body">
  <app-local-spinner [componentId]="localSpinnerId">
    <div
      class="project-draft-history-container d--flex flex__direction--column gap--8"
      #projectDraftHistory
      (scroll)="onScroll(projectDraftHistory)"
    >
      <ng-container *ngIf="projectDraftChangeLogs?.length > 0; else nodataTmpl">
        <app-project-draft-history-item
          *ngFor="let item of projectDraftChangeLogs"
          [item]="item"
          (exportProjectDraftChangelog)="exportChangeLog.emit(item)"
        ></app-project-draft-history-item>
      </ng-container>
    </div>
  </app-local-spinner>
</div>

<ng-template #nodataTmpl>
  <span class="label-xs-medium p__t--8 p__l--8">{{ 'Project.Detail.DraftHistoryLog.NoData.Label' | translate | uppercase }}</span>
</ng-template>
