import { HttpClient, HttpParams } from '@angular/common/http';
import { BehaviorSubject, catchError, finalize, Observable, tap } from 'rxjs';
import { map } from 'rxjs/operators';
import { IApiResponse, IBaseEntity, ISelectOption } from '../interface/base.interface';
import { inject } from '@angular/core';
import { SnackbarService } from '../../../shared/services/snackbar.service';
import { TranslateService } from '@ngx-translate/core';

export abstract class BaseApiService<T> {
  protected abstract apiUrl: string;
  isDetailsLoading$ = new BehaviorSubject<boolean>(false);
  isListLoading$ = new BehaviorSubject<boolean>(false);

  _httpClient: HttpClient;
  snackbarService: SnackbarService;
  translateService: TranslateService;

  constructor() {
    this._httpClient = inject(HttpClient);
    this.snackbarService = inject(SnackbarService);
    this.translateService = inject(TranslateService);
  }

  public getDetailsById(id: string, payload?: HttpParams, showToast?: boolean): Observable<T> {
    this.isDetailsLoading$.next(true);

    const request = this._httpClient
      .get<T>(`${this.apiUrl}/${id}`, { params: payload || {} })
      .pipe(finalize(() => this.isDetailsLoading$.next(false)));

    if (showToast) {
      return this.requestWithToast(request);
    }

    return request;
  }

  public getDetailsByCustomUrl(customUrl: string, payload?: HttpParams, showToast?: boolean): Observable<T> {
    this.isDetailsLoading$.next(true);

    const request = this._httpClient.get<T>(`${customUrl}`, { params: payload }).pipe(finalize(() => this.isDetailsLoading$.next(false)));

    if (showToast) {
      return this.requestWithToast(request);
    }

    return request;
  }

  public getList(params?: HttpParams): Observable<IApiResponse<T>> {
    this.isListLoading$.next(true);
    return this._httpClient.get<IApiResponse<T>>(this.apiUrl, {params}).pipe(
      finalize(() => this.isListLoading$.next(false)),
      map((response) => ({
        ...response,
        items: this.mapCollection(response)
      })),
    );
  }

  public getListByCustomUrl(customUrl: string, showToast?: boolean): Observable<T[]> {
    this.isListLoading$.next(true);
    const request = this._httpClient.get<IApiResponse<T>>(`${this.apiUrl}/${customUrl}`).pipe(
      finalize(() => this.isListLoading$.next(false)),
      map((response) => this.mapCollection(response)),
    );

    if (showToast) {
      return this.requestWithToast(request);
    }

    return request;

  }

  public create(requestPayload: object, url?: string, showToast?: boolean): Observable<T> {
    this.isDetailsLoading$.next(true);
    const request = this._httpClient.post<T>(url || this.apiUrl, requestPayload).pipe(finalize(() => this.isDetailsLoading$.next(false)));

    if (showToast) {
      return this.requestWithToast(request);
    }

    return request;
  }

  public get(url?: string, showToast?: boolean): Observable<T> {
    this.isDetailsLoading$.next(true);
    const request = this._httpClient.get<T>(url || this.apiUrl).pipe(finalize(() => this.isDetailsLoading$.next(false)))

    if (showToast) {
      return this.requestWithToast(request);
    }

    return request;
  }

  public put(requestPayload: object, url?: string): Observable<T> {
    this.isDetailsLoading$.next(true);
    return this._httpClient.put<T>(url || this.apiUrl, requestPayload).pipe(finalize(() => this.isDetailsLoading$.next(false)));
  }

  public patch(requestPayload: object, url?: string, showToast?: boolean): Observable<T> {
    this.isDetailsLoading$.next(true);
    const request = this._httpClient.patch<T>(url || this.apiUrl, requestPayload).pipe(finalize(() => this.isDetailsLoading$.next(false)));

    if (showToast) {
      return this.requestWithToast(request);
    }

    return request;
  }

  public delete(id: string, showToast?: boolean): Observable<T> {
    this.isDetailsLoading$.next(true);
    const request = this._httpClient.delete<T>(`${this.apiUrl}/${id}`).pipe(finalize(() => this.isDetailsLoading$.next(false)));

    if (showToast) {
      return this.requestWithToast(request);
    }

    return request;
  }

  protected mapCollection(response: IApiResponse<T>): T[] {
    return response.items;
  }

  protected mapDetails(response: IApiResponse<T>): T {
    return response.data;
  }

  public mapOption(option: IBaseEntity): ISelectOption {
    return {
      label: option.name,
      value: option.id,
    };
  }

  private requestWithToast(request: Observable<any>) {
    return request.pipe(
      tap(() => {
        this.snackbarService.openSuccessSnackBar(this.translateService.instant('Global.Toast.Label.Success'))
      }),
      catchError((err) => {
        if (Array.isArray(err?.error?.message)) {
          this.snackbarService.openErrorSnackBar(err.error.message[0]);
          throw err;
        }

        this.snackbarService.openErrorSnackBar(err?.error?.message);
        throw err;
      })
    );
  }
}
