<div class="dialog__header flex justify__content--between align__items--center">
  <div class="flex align__items--center">
    {{ dialogTitle | translate }}
    <ng-content select="[slot=dialog-actions]"></ng-content>
  </div>

  <app-svg-icon icon="close-white" class="btn__icon-rectangle--default clickable" (click)="dialogRef.close()"></app-svg-icon>
</div>

<div class="dialog__container">
  <ng-content select="[slot=dialog-container]"></ng-content>
</div>
