import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  Output,
  Renderer2,
  ViewChild
} from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { TranslateService } from '@ngx-translate/core';
import { CommonConstant } from 'src/shared/constants';
import { IconName } from 'src/shared/enums';
import { IGeneralNoticeMessage } from '../dashboard/dashboard.interface';
import {
  buildEditorSlateSchema,
  buildHtmlStringFromSlateEditor, getCountWords,
  replaceLineBreakCharacters
} from '../text-editor-slate/text-editor.util';
import { withHistory } from 'slate-history';
import { withAngular } from 'slate-angular';
import { createEditor } from 'slate';
import { TextEditorToolbarDirective } from '../text-editor-slate/text-editor-toolbar.directive';

@Component({
  selector: 'app-general-notice',
  templateUrl: './general-notice.component.html',
  styleUrls: ['./general-notice.component.scss'],
})
export class GeneralNoticeComponent extends TextEditorToolbarDirective implements AfterViewInit {
  public readonly ICON_NAMES = IconName;
  public originalDataSource: IGeneralNoticeMessage;
  public maxLength = CommonConstant.TEXT_EDITOR_MAX_LENGTH;
  public isActive = false;
  public editor = withHistory(withAngular(createEditor()));

  @Input() isEditable: boolean;
  @Input() iconVisible = true;
  @Input() set dataSource(value: IGeneralNoticeMessage) {
    if (!value) {
      return;
    }
    const text = replaceLineBreakCharacters(value?.content);
    value.content = text;
    this.originalDataSource = value;
    this.editorValue = buildEditorSlateSchema(text);
    this.retainContent = text;

    this._setMessageTextContent(text);
  }

  @Output() iconClicked = new EventEmitter();
  @Output() blurEditor: EventEmitter<IGeneralNoticeMessage> = new EventEmitter<IGeneralNoticeMessage>();
  @Output() focus = new EventEmitter();

  @ViewChild('editableSection') editableSection: ElementRef;
  @ViewChild('messageText', { static: true }) messageText: ElementRef;

  constructor(private _renderer: Renderer2, private _matSnackBar: MatSnackBar, private _translateService: TranslateService) {
    super();
  }

  ngAfterViewInit(): void {
    this._setMessageTextContent(this.retainContent);
  }

  onIconClicked() {
    if (getCountWords(this.retainContent, this.editorValue, false) > 2000 && this.isEditable) {
      this._openSnackBar('Global.Error.MaxLength', CommonConstant.FAILURE_SNACKBAR_CONFIG, { maxLength: this.maxLength });
      return;
    }
    setTimeout(() => this._setMessageTextContent(this.retainContent));
    if (!this.isEditable) {
      setTimeout(() => {
        this.focusSlateEditor();
      }, 500);
      return;
    }
    this.iconClicked.emit({ ...this.originalDataSource, content: this.retainContent ?? '' });
  }

  public slateEditorValueChange(value: Element[]) {
    const retainContent = buildHtmlStringFromSlateEditor(value);
    if (retainContent.length > this.maxLength) {
      return;
    }

    this.retainContent = retainContent;

    if (this.isPasteEventTriggered) {
      this.rebuildEditorValueAfterPaste();
    }
  }

  public onFocus = () => {
    this.isActive = true;
    this.focus.emit();
  }

  public onBlur = () => {
    this.isActive = false;
    this.blurEditor.emit({
      content: this.retainContent
    })
  }

  private _setMessageTextContent(content: string) {
    this._renderer?.setProperty(this.messageText?.nativeElement, 'innerHTML', (content ?? '').replace(/(<p[^>]+?>|<p>|<\/p>)/gim, ''));
  }

  private _openSnackBar(message?: string, config?: object, translateParam?: object) {
    this._matSnackBar.open(
      this._translateService.instant(message ?? 'Global.Toast.Label.Success', translateParam),
      null,
      config ?? CommonConstant.SUCCESS_SNACKBAR_CONFIG,
    );
  }
}
