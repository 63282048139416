import { AudioStatusEnum } from './audio.enum';

export const AUDIO_ICONS = {
  [AudioStatusEnum.Default]: 'audio-play',
  [AudioStatusEnum.Playing]: 'audio-pause',
  [AudioStatusEnum.Recording]: 'check-grey',
  [AudioStatusEnum.Loading]: 'loading-circle',
}

export const AUDIO_MAX_LENGTH = 60000;
