import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'excludeOption',
})
export class ExcludeOptionPipe implements PipeTransform {
  public transform(data: any[], excluded: any): any[] {
    return data.filter((item) => {
      if (typeof item === 'string') {
        return item !== excluded;
      }

      if (typeof item === 'object') {
        return item.id !== excluded.id;
      }

      return true;
    });
  }
}
