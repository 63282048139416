<div
  class="audio-recorder-container px-8 pb-3"
  [class.loading]="isLoading"
>
  <div class="flex w-full p-3 flex-col justify-center gap-3 rounded-lg border--grey-light bg-white">
    <div class="flex justify-between items-center">
      <span class="label-maj-xs--medium uppercase">{{ 'Audio.SoundRecording.Label' | translate }}</span>
      <span
        class="label-min-md--regular"
        *ngIf="audioUrl && audioStatus() === AudioStatusEnum.Default"
      >
        {{ 'Audio.CTA.Play.Label' | translate }}
      </span>
      <span
        class="label-min-md--regular"
        *ngIf="audioUrl && audioStatus() === AudioStatusEnum.Playing"
      >
        {{ 'Audio.CTA.Pause.Label' | translate }}
      </span>
      <span
        class="label-min-md--regular"
        *ngIf="audioStatus() === AudioStatusEnum.Loading"
      >Creation in progress</span>

      <span
        class="label-min-md--regular"
        *ngIf="!audioUrl && !(audioStatus() === AudioStatusEnum.Loading)"
      >
        {{ 'Audio.CTA.Tick.Label' | translate }}
      </span>
    </div>

    <div class="flex items-center gap-3 self-stretch w-full z-0">
      <div
        [ngClass]="isMaxLengthViolated() || isDeleteRequested$() ? 'hidden' : 'flex'"
        class="p-2 items-center gap-2 flex-1 rounded-[20px] min-h-[56px] {{backgroundColor}}"
      >
        <div
          *ngIf="!showError && audioStatus() !== AudioStatusEnum.Loading"
          class="flex relative icon-copy clickable clickable__wrapper btn__icon-rectangle btn__icon-rectangle--default"
          (click)="audioBtnClicked()"
        >
          <app-svg-icon
            [icon]="audioIcon()"
            width="24"
            height="24"
          ></app-svg-icon>
        </div>

        <img
          *ngIf="!showError && audioStatus() === AudioStatusEnum.Loading"
          src="assets/icons/png/icon-loading.png"
          alt="loading"
          class="clickable icon--md d--flex align-items--center infinite-spin"
        >

        <div class="wave-surfer flex-1">
          <div
            #mics
            [class.hidden]="audioStatus() !== AudioStatusEnum.Recording"
          ></div>
          <div
            #recording
            [class.hidden]="(!recordingWavesurfer() && audioStatus() === AudioStatusEnum.Recording) || audioStatus() === AudioStatusEnum.Loading"
          ></div>
        </div>
      </div>

      <div
        *ngIf="showError || (isMaxLengthViolated() || isAudioGenerationFailed()) && !isDeleteRequested$()"
        class="flex flex-1 p-2 gap-3 justify-center items-center rounded-[20px] bg-[#FFFEFB] border--red min-h-[56px]"
      >
        <div
          class="flex relative icon-copy clickable clickable__wrapper btn__icon-rectangle btn__icon-rectangle--default"
          (click)="resetAudio()"
        >
          <app-svg-icon
            icon="recycle"
            width="24"
            height="24"
          ></app-svg-icon>
        </div>

        <div
          *ngIf="!isMaxLengthViolated() && !isAudioGenerationFailed()"
          class="!text-[#D80027] text-left label-maj-xs--medium uppercase"
          [innerHTML]="'Audio.Error.UploadFailed.Label' | translate | sanitizeDom"
        ></div>

        <div
          *ngIf="isMaxLengthViolated()"
          class="!text-[#D80027] text-left label-maj-xs--medium uppercase"
          [innerHTML]="'Audio.Error.MaxLength.Label' | translate | sanitizeDom"
        ></div>

        <div
          *ngIf="isAudioGenerationFailed()"
          class="!text-[#D80027] text-left label-maj-xs--medium uppercase"
          [innerHTML]="'Audio.Error.AIGeneration.Label' | translate | sanitizeDom"
        ></div>
      </div>

      <div
        *ngIf="isDeleteRequested$()"
        class="flex flex-1 py-2 px-6 items-center justify-start bg--grey-dark rounded-[20px] border border-white min-h-[56px]"
      >
        <div
          class="!text-white text-left !leading-[1.2] label-maj-xs--medium uppercase"
          [innerHTML]="'Audio.Delete.Confirm.Label' | translate | sanitizeDom"
        ></div>
      </div>

      <ng-container *ngIf="isDeleteRequested$()">
        <div
          *ngIf="isEditable"
          class="flex gap-3 relative icon-copy clickable clickable__wrapper btn__icon-rectangle btn__icon-rectangle--default"
          (click)="cancelDeleteRequest()"
        >
          <app-svg-icon
            icon="close"
            width="24"
            height="24"
          ></app-svg-icon>
        </div>

        <div
          *ngIf="isEditable"
          class="flex relative icon-copy clickable clickable__wrapper btn__icon-rectangle btn__icon-rectangle--default"
          (click)="confirmDeleteRequest()"
        >
          <app-svg-icon
            icon="check-grey"
            width="24"
            height="24"
          ></app-svg-icon>
        </div>
      </ng-container>

      <ng-container *ngIf="!isDeleteRequested$()">
        <span *ngIf="audioStatus() === AudioStatusEnum.Playing">{{ progress() }}</span>

        <div
          *ngIf="isEditable"
          class="flex relative icon-copy clickable clickable__wrapper btn__icon-rectangle btn__icon-rectangle--default"
          (click)="onBtnDeleteClicked()"
        >
          <app-svg-icon
            icon="trash"
            width="24"
            height="24"
          ></app-svg-icon>
        </div>
      </ng-container>
    </div>
  </div>
</div>
