<div class="container">
  <span *ngIf="title" class="static-title headline--6 d--flex justify__content--center" [innerHTML]="title | translate"></span>
  <p class="description text--long--sm--medium text-center" [innerHTML]="description | translate"></p>

  <div class="group-actions">
    <app-button
      (buttonClicked)="onDismiss()"
      [btnLabel]="'Global.Button.Cancel' | translate"
      [btnClass]="'btn--sm btn__secondary'"
    ></app-button>
    <app-button
      (buttonClicked)="onConfirm()"
      [btnLabel]="'Global.Button.Continue' | translate"
      [btnClass]="'btn--sm btn__default--leading'"
    ></app-button>
  </div>
</div>
