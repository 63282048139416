import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Pipe({
  name: 'optionDisplay',
  standalone: true
})
export class OptionDisplayPipe implements PipeTransform {
  constructor(private _translateService: TranslateService) {
  }

  public transform(option: any, translationObject?: any): string {
    if (typeof option === 'string' && translationObject) {
      return this._translateService.instant(translationObject[option]);
    }

    if (typeof option === 'object') {
      return option.title || option.name || option.id;
    }

    return option;
  }
}
